import { Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class DailyConsumedEnergyWidget extends BaseWidget<{ plantId: string; title: string }> {
  static readonly id = 'daily-consumed-energy';
  static readonly publicFriendly = false;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string; currency: string }
  ) {
    return new DailyConsumedEnergyWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string; currency: string }
  ) {
    super(
      injector,
      DailyConsumedEnergyWidget.id,
      DailyConsumedEnergyWidget.publicFriendly,
      metaLinkId,
      x,
      y,
      rows,
      cols,
      data
    );
    this.resolveComponent(async () => {
      const module = await import('../daily/daily-consumed-energy.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { plantId: string; title: string }): void {
    this.setData({ plantId: data.plantId, title: data.title });
  }
}
